<template>
  <app-card class="skeleton-card">
    <template #media>
      <aspect-ratio class="skeleton-card__image">
        <app-progress/>
      </aspect-ratio>
    </template>
    <div class="skeleton-card__line"></div>
    <div class="skeleton-card__line skeleton-card__line--short"></div>
  </app-card>
</template>

<style scoped>
  .skeleton-card {
    width: 100%;
  }

  .skeleton-card__image {
    background: var(--c-gray-200);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skeleton-card__line {
    width: 100%;
    height: 1rem;
    background: var(--c-gray-200);
    margin-bottom: var(--gap-100);
  }

  .skeleton-card__line--short {
    width: 75%;
  }
</style>
